<template>
  <svg
    width="11"
    height="15"
    viewBox="0 0 11 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 0.75C8.37109 0.75 10.75 3.10156 10.75 6C10.75 7.96875 10.0938 8.57031 6.59375 14.1484C6.07422 14.9688 4.89844 14.9688 4.37891 14.1484C0.878906 8.57031 0.25 7.96875 0.25 6C0.25 3.12891 2.57422 0.75 5.5 0.75ZM5.5 13.4375C9 7.80469 9.4375 7.50391 9.4375 6C9.4375 3.83984 7.66016 2.0625 5.5 2.0625C3.3125 2.0625 1.5625 3.83984 1.5625 6C1.5625 7.50391 2 7.85938 5.5 13.4375ZM3.3125 6C3.3125 4.79688 4.26953 3.8125 5.5 3.8125C6.70312 3.8125 7.6875 4.79688 7.6875 6C7.6875 7.23047 6.70312 8.1875 5.5 8.1875C4.26953 8.1875 3.3125 7.23047 3.3125 6Z"
      fill="currentColor"
    />
  </svg>
</template>
